.swiper-button {
    &-prev,
    &-next {
        &::after {
            font-size: 1.8rem;
        }

        // TODO decide if we want to keep overriding `.is-small` here or make `.is-small` brand specific eg. using spotConfig
        &.is-small {
            @include desktop {
                --swiper-navigation-size: #{$swiper-button-size-base};
            }
        }
    }
}
