.mega-menu {
    &__wrapper {
        &::before {
            top: 0;
            height: $mega-menu-border-top-space;
            background-color: $header-bg-color;
            border-bottom: $mega-menu-border-top-height solid $mega-menu-border-top-background-color;
        }
    }
}
